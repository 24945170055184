import {browserVersion, isChrome} from 'react-device-detect';

export const setCookie = (name: string, value: string, days: number, isStrict?: boolean): void => {
  const date = new Date();
  let cookieStr;
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toString()}`;
  cookieStr = `${name}=${value}${expires}; path=/;domain=${window.PMW.DOMAIN}`;

  if (isChrome && parseInt(browserVersion, 10) < 79) {
    cookieStr += ';Secure;';
  } else if (isStrict) {
    cookieStr += ' ;SameSite=Strict;Secure;';
  } else {
    cookieStr += ' ;SameSite=None;Secure;';
  }

  document.cookie = cookieStr;
};

export const readCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${encodeURIComponent(name)}=deleted; expires=${new Date(0).toUTCString()};path=/ ;domain=${window.PMW.DOMAIN}`;
};
